@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// rtl Direction
// @import "./custom/rtl/general-rtl";
// @import "./custom/rtl/bootstrap-rtl";
// @import "./custom/rtl/spacing-rtl";
// @import "./custom/rtl/float-rtl";
// @import "./custom/rtl/text-rtl";
// @import "./custom/rtl/structure-rtl";
// @import "./custom/rtl/plugins-rtl";
// @import "./custom/rtl/components-rtl";
// @import "./custom/rtl/pages-rtl";


body {
  * {
    outline: none;
  }
}

.metismenu li:hover span, .metismenu li:hover i, #sidebar-menu ul li a:hover {
  color: #000972 !important;
}

.mm-active .active {
  color: #000972 !important;
}

.mm-active .active i{
  color: #000972 !important;
}

.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

.invalid-feedback {
  display: block;
}
  .field {
    position: relative;
    margin: auto;
    width: 100%;
    // max-width: 280px;
    border-radius: 3px;
    overflow: hidden;
  }
  .field .label {
    position: absolute;
    top: 17px;
    left: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 500;
    transform-origin: 0 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
    pointer-events: none;
  }
  .field .focus-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, 0.05);
    z-index: -1;
    transform: scaleX(0);
    transform-origin: left;
  }
  .field input, .field select, .field textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    width: 100%;
    border: 1px solid #ddd;
    font-family: inherit;
    padding: 16px 12px 0 12px;
    height: 56px;
    font-size: 16px;
    font-weight: 400;
    // background: rgba(0, 0, 0, 0.02);
    // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
    color: #000;
    transition: all 0.15s ease;
  }
  .field input:hover, .field select:hover, .field textarea:hover {
    // background: rgba(0, 0, 0, 0.04);
    // box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
  }
  .field input:not(:-moz-placeholder-shown) + .label,
  .field textarea:not(:-moz-placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .field input:not(:-ms-input-placeholder) + .label ,
  .field textarea:not(:-ms-input-placeholder) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .field input:not(:placeholder-shown) + .label,
  .field textarea:not(:placeholder-shown) + .label {
    color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .field input:focus ,
  .field textarea:focus {
    // background: rgba(0, 0, 0, 0.05);
    outline: none;
    border: 1px solid #000972;
  }
  .field input:focus + .label,
  .field textarea:focus + .label {
    color: #000972;
    transform: translate3d(0, -12px, 0) scale(0.75);
  }
  .field input:focus + .label + .focus-bg,
  .field textarea:focus + .label + .focus-bg {
    transform: scaleX(1);
    transition: all 0.1s ease;
  }

.field select {
padding-top: 0;
  }

  ///////////////////////////////
  /// 
  /// 

  
  .filelabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  
  .fileinput {
    opacity: 0;
    width: 0px;
  }
  
  .logoimg {  
    max-width: 250px;
    width: 250px;
    height: auto;
    // border-radius: 999px;
    // outline: 3px solid var(--primary);
    cursor: pointer;
    transition: 200ms ease-in-out;
    
  /* prevents uploaded image from distortion: */
    // aspect-ratio: 1;
    // object-fit: cover;
  }
  
  .logoimg:hover {
    outline: 8px solid var(--primary);
  }
  
.public-input {
  padding: 10px;
   
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.public-input-info {
  padding: 5px;
  
}

.public-input-info h4 {
  font-size: 16px !important;

}

.public-input-info span {
  font-size: 16px;
  
}

.public-input-button {
  padding: 10px;
    background: #000972;
    color: #fff;
    font-size: 18px;
    // /* border: none; */
    // /* outline: none; */
    border-radius: 10px;
    cursor: pointer;
    height: 100%;
}


/////////////
/// 
.public-apple {
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  
}

.public-apple-button {
  display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    font-size: 16px;
}

.public-apple-button:last-child {
  border-bottom: 0;
}

.public-apple-button i {
  padding: 6px;
  background: #000972;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.public-apple-button h4 {
  font-size: 14px;
}

/////////////////
/// 
/// 
.public-apple2 {
  border: 1px solid #ddd;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
}

.public-apple-button2 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    align-items: center;
    font-size: 16px;
}

.public-apple-button2:last-child {
  border-bottom: 0;
}

.public-apple-button2 i {
  padding: 6px;
  background: #000972;
  color: #fff;
  font-size: 13px;
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}

.public-apple-button2 h4 {
  font-size: 14px;
}

@media (max-width: 767.98px) { 
.hide-on-mobile {
display: none !important;
}
}